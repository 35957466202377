
import { ref, watch, computed, defineComponent } from 'vue';
import moment from 'moment';

export default defineComponent({
  name: 'DateRangePicker',
  components: {
	
  },
  props: ['date', 'clearable', 'format', 'enableTimePicker'],
  emits: ['update:date'],
  setup(props, { emit }) {
    
    const date = ref(props.date);
    const computedEnableTimePicker = computed(() => props.enableTimePicker ?? true);

    function format(e) {

      if(e && Array.isArray(e) && e.length > 1) {

        const format = props.format ? props.format : 'DD/MM/YYYY, hh:mm A';
        return moment(e[0]).format(format) + ' - ' + moment(e[1]).format(format);

      }

    }

    const datepickerRangeRef = ref();

    return {
      format,
      date,
      datepickerRangeRef,
      computedEnableTimePicker,
    };

  }
});
